import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { SimpleAuthenticationParams } from 'src/app/shared/models/authentication';
import { InvitationResult } from 'src/app/shared/models/user';
import { usersFeature } from '../user/user.state';
import * as AuthenticationActions from './authentication.actions';

interface AuthenticationState {
  isLogged: boolean;
  isLoggedIntoTerminal: boolean;
  unauthSpinner: boolean;
  unauthAggregatedOrderFeature: boolean;
  unauthInvalidUrlMessage: boolean;
  loginSpinner: boolean;
  resetSpinner: boolean;
  registerSpinner: boolean;
  confirmEmailLoading: boolean;
  confirmEmailSuccessOrFail: boolean | undefined;
  invitationError: string;
  invitationResult: InvitationResult;
  authKeyAndId: SimpleAuthenticationParams;
  redirectionUrl: string;
}

const initialState: AuthenticationState = {
  isLogged: false,
  isLoggedIntoTerminal: false,
  unauthSpinner: false,
  unauthAggregatedOrderFeature: false,
  unauthInvalidUrlMessage: false,
  loginSpinner: false,
  resetSpinner: false,
  registerSpinner: false,
  confirmEmailLoading: false,
  confirmEmailSuccessOrFail: undefined,
  invitationError: null,
  invitationResult: null,
  authKeyAndId: null,
  redirectionUrl: null,
};

const reducer = createReducer<AuthenticationState>(
  initialState,
  on(AuthenticationActions.setLoginButtonState, (state, { login_spinner }) => ({
    ...state,
    loginSpinner: login_spinner,
  })),
  on(AuthenticationActions.setResetButtonState, (state, { reset_spinner }) => ({
    ...state,
    resetSpinner: reset_spinner,
  })),
  on(
    AuthenticationActions.setRegisterButtonState,
    (state, { register_spinner }) => ({
      ...state,
      registerSpinner: register_spinner,
    }),
  ),
  on(AuthenticationActions.setLogin, (state, { isLogged }) => ({
    ...state,
    isLogged,
  })),
  on(AuthenticationActions.setAuthKeyAndId, (state, { payload }) => ({
    ...state,
    authKeyAndId: payload,
  })),
  on(AuthenticationActions.setProposedEmail, (state, { email }) => ({
    ...state,
    proposedEmail: email,
  })),
  on(
    AuthenticationActions.setConfirmEmailLoading,
    (state, { payload: loadingState }) => ({
      ...state,
      confirmEmailLoading: loadingState,
    }),
  ),
  on(
    AuthenticationActions.setConfirmEmailSuccessOrFail,
    (state, { payload }) => ({
      ...state,
      confirmEmailSuccessOrFail: payload,
    }),
  ),
  on(
    AuthenticationActions.setLoggedIntoTerminal,
    (state, { isLoggedIntoTerminal }) => ({
      ...state,
      isLoggedIntoTerminal,
    }),
  ),
  on(AuthenticationActions.setInvitationError, (state, { message }) => ({
    ...state,
    invitationError: message,
  })),
  on(AuthenticationActions.setInvitationResult, (state, { payload }) => ({
    ...state,
    invitationResult: payload,
  })),
  on(AuthenticationActions.setUnauthSpinner, (state, { value }) => ({
    ...state,
    unauthSpinner: value,
  })),
  on(
    AuthenticationActions.setUnauthAggregatedOrderFeature,
    (state, { enable }) => ({
      ...state,
      unauthAggregatedOrderFeature: enable,
    }),
  ),
  on(AuthenticationActions.setUnauthInvalidUrlMessage, (state, { show }) => ({
    ...state,
    unauthInvalidUrlMessage: show,
  })),
  on(AuthenticationActions.setRedirectionUrl, (state, { url }) => ({
    ...state,
    redirectionUrl: url,
  })),
);

export const authenticationFeature = createFeature({
  name: 'authentication',
  reducer,
  extraSelectors: ({
    selectIsLogged,
    selectIsLoggedIntoTerminal,
    selectAuthKeyAndId,
  }) => ({
    selectIsSimpleAuth: createSelector(
      selectAuthKeyAndId,
      (authKeyAndId) => !!authKeyAndId,
    ),
    selectIsFullyLogged: createSelector(
      selectIsLogged,
      selectIsLoggedIntoTerminal,
      usersFeature.selectIsTerminal,
      usersFeature.selectIsUser,
      selectAuthKeyAndId,
      (isLogged, isLoggedTerminal, isTerminal, isUser, isSimpleAuth) =>
        isLogged &&
        ((isTerminal && isLoggedTerminal) || isUser || !!isSimpleAuth),
    ),
  }),
});
