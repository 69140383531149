import { createAction, props } from '@ngrx/store';

import {
  GenericName,
  LoginButtonState,
  LoginForm,
  LoginState,
  RegisterButtonState,
  RegistrationForm,
  ResetButtonState,
  SetLoggedIntoTerminal,
  SimpleAuthenticationParams,
} from '../../shared/models/authentication';
import { InvitationResult } from '../../shared/models/user';

export const login = createAction(
  `[Login Container] Attempt to login with LoginForm data`,
  props<LoginForm & { formId: string }>(),
);

export const logout = createAction(
  `[Logging out completely] Due to an error or a user action logging out, clearing token and User object`,
);

export const setLogin = createAction(
  `[Login Container] Set login state`,
  props<LoginState>(),
);

export const setLoginButtonState = createAction(
  `[Login Container] Set login button state`,
  props<LoginButtonState>(),
);

export const setUnauthSpinner = createAction(
  `[Login container (unauth)] Set unauth spinner`,
  props<{ value: boolean }>(),
);

export const setUnauthAggregatedOrderFeature = createAction(
  `[Login container (unauth)] Set Aggregated Order Feature`,
  props<{ enable: boolean }>(),
);

export const setUnauthInvalidUrlMessage = createAction(
  `[Login container (unauth)] Set Aggregated Order Feature`,
  props<{ show: boolean }>(),
);

export const setAuthKeyAndId = createAction(
  `[Authentication API] Set key and id (from query params)`,
  props<{ payload: SimpleAuthenticationParams }>(),
);

export const setLoggedIntoTerminal = createAction(
  `[Login Container] Set state of user being logged into terminal`,
  props<SetLoggedIntoTerminal>(),
);

export const setProposedEmail = createAction(
  `[Registration Container] Set alternative email of async validation`,
  props<{ email: string }>(),
);

export const register = createAction(
  `[Registration Container] Attempt to register a user with RegistrationForm data`,
  props<{ payload: RegistrationForm }>(),
);

export const setRegisterButtonState = createAction(
  `[Registration Container] Set Register button state`,
  props<RegisterButtonState>(),
);

export const resetPassword = createAction(
  `[Reset password Container] Send request to reset user's password`,
  props<{ email: string; formId: string }>(),
);

export const setResetButtonState = createAction(
  `[Reset password Container] Set reset button state`,
  props<ResetButtonState>(),
);

export const confirmEmail = createAction(
  `[Confirm email Container] Send request to confirm user's email`,
  props<{ key: string }>(),
);

export const setConfirmEmailLoading = createAction(
  `[Confirm email Container] Set state of email confirmation progress, it will show or hide the spinner`,
  props<{ payload: boolean }>(),
);

export const setConfirmEmailSuccessOrFail = createAction(
  `[Confirm email Container] Set success or failure of email confirmation`,
  props<{ payload: boolean }>(),
);

export const confirmPasswordReset = createAction(
  `[Confirm password Container] Send confirmation to reset user's password`,
  props<{
    payload: { new_password1: string; new_password2: string };
    formId: string;
  }>(),
);

export const logoutCompletely = createAction(
  `[Menus container] Logout completely (as terminal)`,
);

export const logoutPartially = createAction(
  `[Menus container] Logout as consumer (terminal user stays logged in)`,
);

export const resendEmailConfirmation = createAction(
  `[Verify email component] Resend email confirmation`,
);

export const retrieveUuid = createAction(
  `[Login Container (unauth)] Attempt to retrieve uuid `,
  props<{ consumerName?: GenericName; key?: string; roomNo?: string }>(),
);

export const validateKey = createAction(
  `[Login Container (unauth)] Attempt to validate key `,
  props<{ key?: string }>(),
);

export const redirectAfterLoggingOut = createAction(
  `[Settings container] Redirect to the Login page after user was deleted`,
);

export const cleanStates = createAction(`[Authentication API] Clean state`);

export const showDialogToVerifyPassword = createAction(
  `[Authentication API] Show dialog to verify a password`,
);

export const verifyPasswordAndRedirectToSettings = createAction(
  `[Settings API] Verify terminal's password and redirect to settings`,
  props<{ password: string; formId: string }>(),
);

export const verifyPasswordAndLogout = createAction(
  `[Authentication API] Verify terminal's password and logout completely`,
  props<{ password: string; formId: string }>(),
);

export const checkInvitation = createAction(
  `[Registration container] Check invitation key`,
  props<{ key: string }>(),
);

export const setInvitationError = createAction(
  `[Invitation API] Set invitation error message`,
  props<{ message: string }>(),
);

export const setInvitationResult = createAction(
  `[Invitation API] Set invitation result`,
  props<{ payload: InvitationResult }>(),
);

export const handleConfirmPasswordResetError = createAction(
  `[Authentication API] Handle confirm password reset server errors`,
  props<{ formId: string; error: unknown }>(),
);

export const setRedirectionUrl = createAction(
  `[Authentication API] Set redirection url`,
  props<{ url: string }>(),
);
