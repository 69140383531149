import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { showDialogToVerifyPassword } from 'src/app/store/authentication/authentication.actions';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';
import { usersFeature } from 'src/app/store/user/user.state';
import { UtilsService } from 'src/app/shared/services/utils.service';

const authenticationRoutes = [
  '/login',
  '/registration',
  '/reset-password',
  '/reset',
  '/confirm-email',
];

export const loginGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const store = inject(Store);
  const utilsService = inject(UtilsService);

  const isLogged$ = store.pipe(
    select(authenticationFeature.selectIsLogged),
    take(1),
  );
  const isLoggedFully$ = store.pipe(
    select(authenticationFeature.selectIsFullyLogged),
    take(1),
  );
  const isTerminal$ = store.pipe(
    select(usersFeature.selectIsTerminal),
    take(1),
  );
  const isSimpleConsumer$ = store.pipe(
    select(usersFeature.selectSimpleConsumer),
    take(1),
  );

  const handleAuthRoutes = (
    state: RouterStateSnapshot,
    isLogged: boolean,
  ): Observable<boolean> => {
    if (isLogged && !state.url.startsWith('/email-verification')) {
      router.navigate(['menus'], {
        queryParams: state.root.queryParams,
      });
      return of(false);
    }
    return of(true);
  };

  const handleTerminalSettings = (): Observable<boolean> => {
    utilsService.enableAutofocus.next(false);
    store.dispatch(showDialogToVerifyPassword());
    return of(false);
  };

  const handleUnauthenticated = (
    state: RouterStateSnapshot,
  ): Observable<boolean> => {
    const queryParams = {};
    if (state.root.queryParams.key && state.root.queryParams.auth_room) {
      queryParams['key'] = state.root.queryParams.key as string;
      queryParams['auth_room'] = state.root.queryParams.auth_room as string;
    }
    router.navigate(['login'], { queryParams });
    return of(false);
  };

  const forceToSettings = router.getCurrentNavigation().extras?.state
    ?.forceToSettings as boolean;
  if (forceToSettings) return of(true);

  return isLogged$.pipe(
    withLatestFrom(isLoggedFully$, isTerminal$, isSimpleConsumer$),
    switchMap(([isLogged, isFullyLogged, isTerminal, isSimpleConsumer]) => {
      if (
        state.url.startsWith('/email-verification') ||
        state.url.startsWith('/confirm-email')
      ) {
        return of(true);
      }
      if (isTerminal && state.url === `/settings`) {
        return handleTerminalSettings();
      }
      if (isTerminal && !isFullyLogged) {
        if (state.url.startsWith('/terminal')) {
          return of(true);
        }
        router.navigate(['terminal']);
        return of(true);
      }
      if (
        (isLogged || isSimpleConsumer) &&
        (state.url === '/' || state.url.startsWith('/?') || state.url === '')
      ) {
        router.navigate(['menus'], { queryParams: state.root.queryParams });
        return of(true);
      }
      if (authenticationRoutes.some((route) => state.url.startsWith(route))) {
        return handleAuthRoutes(state, isLogged);
      }
      if (!isLogged) return handleUnauthenticated(state);

      return of(true);
    }),
  );
};
