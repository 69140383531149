import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  OrganisationFeatures,
  RoleChoice,
  RoomConsumer,
  TerminalType,
  UnauthConsumer,
  User,
} from 'src/app/shared/models/user';
import {
  setConsumer,
  setRoomDetail,
  setUnauthConsumer,
  setUser,
} from './user.actions';

interface UserState {
  currentUser: User;
  currentConsumer: Consumer;
  simpleConsumer: UnauthConsumer;
  roomDetail: RoomConsumer[] | { message: string };
}

const initialState: UserState = {
  currentUser: null,
  currentConsumer: null,
  simpleConsumer: null,
  roomDetail: null,
};

const reducer = createReducer(
  initialState,
  on(setUser, (state, { payload }) => ({ ...state, currentUser: payload })),
  on(setConsumer, (state, { payload }) => ({
    ...state,
    currentConsumer: payload,
  })),
  on(setRoomDetail, (state, { payload }) => ({
    ...state,
    roomDetail: payload,
  })),
  on(setUnauthConsumer, (state, { simpleConsumer }) => ({
    ...state,
    simpleConsumer,
  })),
);

export const usersFeature = createFeature({
  name: 'user',
  reducer,
  extraSelectors: ({ selectCurrentUser, selectSimpleConsumer }) => ({
    selectTerminal: createSelector(selectCurrentUser, (user) => user?.terminal),

    selectIsTerminal: createSelector(
      selectCurrentUser,
      (user) => !!user?.terminal,
    ),

    selectIsUser: createSelector(selectCurrentUser, (user) => !user?.terminal),

    selectOrganisation: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, consumer) => user?.organisation || consumer?.organisation,
    ),

    selectLogo: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, consumer) =>
        user?.location && user?.location_detail?.logo
          ? user?.location_detail?.logo
          : (user?.organisation || consumer?.organisation)?.logo || null,
    ),

    selectEmailVerified: createSelector(
      selectCurrentUser,
      (user) => user?.status?.email_verified,
    ),

    selectUserFullName: createSelector(selectCurrentUser, (user) =>
      user ? `${user?.first_name} ${user?.last_name}` : undefined,
    ),

    selectUserLocation: createSelector(selectCurrentUser, (user) =>
      user ? user?.location : undefined,
    ),

    selectUserAllowOrderAllergies: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, consumer) =>
        user?.location
          ? user?.location_detail?.allow_order_allergies
          : (user?.organisation || consumer?.organisation)
              ?.allow_order_allergies,
    ),

    selectUserAllowOrderIntolerances: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, consumer) =>
        user?.location
          ? user?.location_detail?.allow_order_intolerances
          : (user?.organisation || consumer?.organisation)
              ?.allow_order_intolerances,
    ),

    selectShowQuantity: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, simpleConsumer) =>
        !!simpleConsumer &&
        (simpleConsumer?.location_detail
          ? simpleConsumer?.location_detail?.unauth_consumers_quantity_selection
          : (user?.organisation || simpleConsumer?.organisation)
              ?.unauth_consumers_quantity_selection),
    ),

    selectShowPortionSize: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, simpleConsumer) =>
        !!simpleConsumer &&
        (simpleConsumer?.location_detail
          ? simpleConsumer?.location_detail
              ?.unauth_consumers_portion_size_selection
          : (user?.organisation || simpleConsumer?.organisation)
              ?.unauth_consumers_portion_size_selection),
    ),

    selectQuantityInputField: createSelector(
      selectSimpleConsumer,
      selectCurrentUser,
      (simpleConsumer, user) => {
        if (simpleConsumer) {
          return simpleConsumer.location_detail
            ? simpleConsumer.location_detail.quantity_input_field
            : simpleConsumer?.organisation?.quantity_input_field;
        }
        return user?.location
          ? user.location_detail.quantity_input_field
          : user?.organisation?.quantity_input_field;
      },
    ),

    selectQuantityFieldIncrement: createSelector(
      selectSimpleConsumer,
      selectCurrentUser,
      (simpleConsumer, user) => {
        if (simpleConsumer) {
          return simpleConsumer.location_detail
            ? simpleConsumer.location_detail.quantity_input_field_increment
            : simpleConsumer.organisation?.quantity_input_field_increment;
        }
        return user?.location
          ? user.location_detail.quantity_input_field_increment
          : user?.organisation?.quantity_input_field_increment;
      },
    ),

    selectConsumerSorting: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.sorting_consumers,
    ),

    selectUsersSorting: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.sorting_users,
    ),

    selectOrdersSorting: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.sorting_orders,
    ),

    selectUserColumns: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.columns_users,
    ),

    selectOrderColumns: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.columns_orders,
    ),

    selectConsumerColumns: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.columns_consumers,
    ),

    selectConsumerId: createSelector(selectCurrentUser, (user) => user?.id),

    selectUserEmail: createSelector(selectCurrentUser, (user) => user?.email),

    selectIsConsumer: createSelector(selectCurrentUser, (user) =>
      [RoleChoice.CONSUMER].includes(user?.role),
    ),

    selectIsAdmin: createSelector(selectCurrentUser, (user) =>
      [RoleChoice.ADMIN].includes(user?.role),
    ),

    selectIsManager: createSelector(selectCurrentUser, (user) =>
      [RoleChoice.ADMIN, RoleChoice.EDITOR].includes(user?.role),
    ),

    selectIsManagerUser: createSelector(
      selectCurrentUser,
      (user) =>
        [RoleChoice.ADMIN, RoleChoice.EDITOR].includes(user?.role) &&
        !user?.terminal,
    ),

    selectIsAgent: createSelector(selectCurrentUser, (user) =>
      [RoleChoice.AGENT].includes(user?.role),
    ),

    selectIsManagerOrAgent: createSelector(selectCurrentUser, (user) =>
      [RoleChoice.ADMIN, RoleChoice.EDITOR, RoleChoice.AGENT].includes(
        user?.role,
      ),
    ),

    selectIsManagerOrAgentUser: createSelector(
      selectCurrentUser,
      (user) =>
        [RoleChoice.ADMIN, RoleChoice.EDITOR, RoleChoice.AGENT].includes(
          user?.role,
        ) && !user?.terminal,
    ),

    selectIsKdsUser: createSelector(selectCurrentUser, (user) => user?.is_kds),

    selectIsServiceUser: createSelector(
      selectCurrentUser,
      (user) => user?.is_service,
    ),

    selectUserSettingsLanguage: createSelector(
      selectCurrentUser,
      (user) => user?.settings?.language,
    ),

    selectUserStatusOrderUntil: createSelector(
      selectCurrentUser,
      (user) => user?.status?.orderby_until,
    ),

    selectFeature: (feature: OrganisationFeatures) =>
      createSelector(
        selectCurrentUser,
        selectSimpleConsumer,
        (user, consumer) =>
          (
            user?.organisation || consumer?.organisation
          )?.enabled_features?.includes(feature),
      ),

    selectEnabledFeatures: createSelector(
      selectCurrentUser,
      selectSimpleConsumer,
      (user, consumer) =>
        (user?.organisation || consumer?.organisation)?.enabled_features,
    ),

    selectJoinedDate: createSelector(
      selectCurrentUser,
      (user) => user?.organisation?.date_joined,
    ),

    selectDisabledRepeatOptions: createSelector(selectCurrentUser, (user) =>
      user?.location && user?.location_detail?.disabled_repetition_modes
        ? user?.location_detail?.disabled_repetition_modes
        : user?.organisation?.disabled_repetition_modes,
    ),

    selectDisabledPortionSizes: createSelector(selectCurrentUser, (user) =>
      user?.location && user?.location_detail?.disabled_portion_sizes
        ? user?.location_detail?.disabled_portion_sizes
        : user?.organisation?.disabled_portion_sizes,
    ),

    selectOrderUntil: createSelector(selectCurrentUser, (user) =>
      user?.location && user?.location_detail?.order_days_until
        ? user?.location_detail?.order_days_until
        : user?.organisation?.order_days_until,
    ),

    selectManageOrdersDefaultDays: createSelector(selectCurrentUser, (user) =>
      user?.location && user?.location_detail?.manage_orders_default_days
        ? user?.location_detail?.manage_orders_default_days
        : user?.organisation?.manage_orders_default_days,
    ),

    selectDefaultManageAccessesStatus: createSelector(
      selectCurrentUser,
      (user) =>
        user?.location && user?.location_detail?.manage_users_default_filter
          ? user?.location_detail?.manage_users_default_filter
          : user?.organisation?.manage_users_default_filter,
    ),

    selectDefaultManageConsumersStatus: createSelector(
      selectCurrentUser,
      (user) =>
        user?.location && user?.location_detail?.manage_consumers_default_filter
          ? user?.location_detail?.manage_consumers_default_filter
          : user?.organisation?.manage_consumers_default_filter,
    ),

    selectRealTimeOrders: createSelector(selectCurrentUser, (user) =>
      user?.location
        ? user?.location_detail?.real_time_orders
        : user?.organisation?.real_time_orders,
    ),

    selectSupportedCustomData: createSelector(
      selectCurrentUser,
      (user) => user?.organisation?.supported_custom_data,
    ),

    selectSupportedCustomDataConsumers: createSelector(
      selectCurrentUser,
      (user) => user?.organisation?.supported_custom_data_consumers,
    ),

    selectAllergensExtended: createSelector(
      selectCurrentUser,
      (user) => user?.organisation?.allergens_extended,
    ),

    selectAllergensExtendedLactose: createSelector(
      selectCurrentUser,
      (user) => user?.organisation?.allergens_lac_extended,
    ),
    selectTerminalTypeCanteen: createSelector(
      selectCurrentUser,
      (user) => user?.terminal?.type === TerminalType.CANTEEN,
    ),
    selectTerminalType: createSelector(
      selectCurrentUser,
      (user) => user?.terminal?.type,
    ),
    selectTerminalPrivacy: createSelector(
      selectCurrentUser,
      (user) => user?.terminal?.privacy,
    ),
  }),
});
